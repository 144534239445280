import React, { useState } from 'react';
import { Box, TextField, Button, Typography, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getLoginCredentials } from './utils/getLoginCredentials'; // Import the utility function

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async () => {
    setError('');
    setLoading(true);

    const credentials = await getLoginCredentials(); // Fetch credentials using the utility function

    if (credentials) {
      // Check if entered credentials match any from the fetched data
      const userCredentials = credentials[username];
      if (userCredentials && userCredentials.pass === password) {
        const branch = userCredentials.branch; // Get branch name
        navigate('/PunchingPage', { state: { branch } }); // Pass branch name to the punching page
      } else {
        setError('Invalid username or password');
      }
    } else {
      setError('Error fetching login credentials');
    }

    setLoading(false); // Reset loading state
  };

  return (
    <Box 
      display="flex" 
      flexDirection="column" 
      alignItems="center" 
      justifyContent="center" 
      height="100vh"
      sx={{ backgroundColor: '#f5f5f5', padding: '20px', borderRadius: '8px' }} // Improved styling
    >
      <Typography variant="h4" gutterBottom>
        Login
      </Typography>
      <TextField
        label="Username"
        variant="outlined"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        sx={{ marginBottom: '20px', width: '300px' }} // Set width for uniformity
      />
      <TextField
        label="Password"
        type="password"
        variant="outlined"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        sx={{ marginBottom: '20px', width: '300px' }} // Set width for uniformity
      />
      {error && (
        <Typography color="error" sx={{ marginBottom: '20px' }}>
          {error}
        </Typography>
      )}
      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleLogin} 
        disabled={loading}
        sx={{ width: '300px' }} // Set width for the button
      >
        {loading ? <CircularProgress size={24} color="inherit" /> : 'Login'}
      </Button>
    </Box>
  );
};

export default LoginPage;
