import React from 'react';
import { Button, Box, Typography, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
  const navigate = useNavigate();

  return (
    
    <Box 
      display="flex" 
      flexDirection="column" 
      alignItems="center" 
      justifyContent="center" 
      height="100vh"
      sx={{ backgroundColor: '#f5f5f5', padding: '20px', borderRadius: '8px' }}
    >
      <Typography variant="h3" gutterBottom>
        Welcome to the Punching System
      </Typography>
      <Stack direction="row" spacing={2}>
 
      <Button 
        variant="contained" 
        color="primary" 
        onClick={() => navigate('/PunchingDetails')}
        sx={{ width: '150px', height: '50px' }}
      >
        Branch Punch details
      </Button>
     
      </Stack>
    </Box>
    
  );
};

export default HomePage;
