import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemText, Toolbar, Box, Divider, Typography, ListItemButton, IconButton, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const drawerWidth = 240;

const SideDrawer = ({ pageName }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)'); // Adjust the breakpoint as needed
  const [mobileOpen, setMobileOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(true);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerOpenClose = () => {
    setDrawerOpen(!drawerOpen);
  };

  const drawerContent = (
    <Box sx={{ overflow: 'auto', padding: '16px' }}>
      <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
        {pageName}
      </Typography>
      <Divider sx={{ margin: '8px 0' }} />
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate('/PunchingDetails')}>
            <ListItemText primary="Punching Details" />
          </ListItemButton>
        </ListItem>
        {/* Add more menu items here if needed */}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <Toolbar>
        {isMobile && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
        )}
      </Toolbar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerOpen ? drawerWidth : 60 }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant={isMobile ? 'temporary' : 'permanent'}
          open={isMobile ? mobileOpen : true}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' }, // Hide drawer on small screens
            [`& .MuiDrawer-paper`]: { boxSizing: 'border-box', width: drawerOpen ? drawerWidth : 60, top: '64px' }, // Adjust top to start below AppBar
          }}
        >
          {drawerContent}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' }, // Show drawer on large screens
            [`& .MuiDrawer-paper`]: { boxSizing: 'border-box', width: drawerOpen ? drawerWidth : 60, top: '64px' }, // Adjust top to start below AppBar
          }}
          open
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '8px' }}>
            <IconButton onClick={handleDrawerOpenClose}>
              {drawerOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </Box>
          {drawerOpen && drawerContent}
        </Drawer>
      </Box>
    </Box>
  );
};

export default SideDrawer;
