import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './index.css';
import LoginPage from './LoginPage';
import PunchingPage from './PunchingPage';
import HomePage from './HomePage';
import AdminLandingPage from './AdminLandingPage';
import PunchingDetails from './PunchingDetails'; // Import the PunchingDetails component

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} /> {/* Set HomePage as the default route */}
        <Route path="/login" element={<LoginPage />} />
        <Route path="/admin" element={<AdminLandingPage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/PunchingPage" element={<PunchingPage />} />
        <Route path="/PunchingDetails" element={<PunchingDetails />} />
        {/* <Route path="/login" element={<LoginPage />} /> */}
  
       
      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
