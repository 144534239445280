import React, { useState, useEffect } from 'react';
import {
  Button,
  Typography,
  Box,
  Grid,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { exportToCSV } from './utils/exportToCSV'; // Import the utility function
import CameraCapture from './CameraCapture'; // Import the CameraCapture component
import HeaderBar from './components/HeaderBar';

const PunchingPage = () => {
  const [punchTime, setPunchTime] = useState(null);
  const [systemName, setSystemName] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [isPunchButtonClicked, setIsPunchButtonClicked] = useState(false); // State to track if Punch Now button is clicked
  const location = useLocation();
  const navigate = useNavigate();
  const branchName = location.state?.branch || 'Unknown Branch';

  const handlePunchNow = () => {
    const currentDateTime = new Date();
    setPunchTime(currentDateTime.toLocaleString());
    setIsCameraOpen(true);
    setIsPunchButtonClicked(true); // Disable Punch Now button permanently after one click
  };

  const handleLogout = () => {
    navigate('/');
  };

  const handleSaveToCSV = () => {
    if (punchTime) {
      exportToCSV(branchName, punchTime, systemName, photo);
    }
  };

  const handlePhotoCapture = (imageData) => {
    setPhoto(imageData);
    setIsCameraOpen(false);
  };

  useEffect(() => {
    const fetchSystemName = async () => {
      try {
        const response = await fetch('http://localhost:5000/hostname');
        const data = await response.json();
        setSystemName(data.hostname);
      } catch (error) {
        console.error('Error fetching system name:', error);
      }
    };

    fetchSystemName();
  }, []);

  return (
 
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="120vh"
      
      sx={{ backgroundColor: '#f5f5f5', padding: '50px', borderRadius: '8px' }}
    >
      <HeaderBar />
      <Grid container spacing={4} justifyContent="center" sx={{ width: '100%', maxWidth: 'md', padding: 4 }}>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom align="center">
            Branch Punching System
          </Typography>
          <Typography variant="h5" gutterBottom align="center">
            Branch: {branchName}
          </Typography>
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handlePunchNow}
            sx={{ marginBottom: '2px' }}
            disabled={isPunchButtonClicked} // Disable button if clicked
          >
            Punch Now
          </Button>
        </Grid>
        {punchTime && (
          <Grid item xs={12} textAlign="center">
            <Typography variant="h6" sx={{ marginTop: '2px' }}>
              Punch Time: {punchTime}
            </Typography>
          </Grid>
        )}
        {/* {systemName && (
          <Grid item xs={12} textAlign="center">
            <Typography variant="h6" sx={{ marginTop: '1px' }}>
              System Name: {systemName}
            </Typography>
          </Grid>
        )} */}
        <CameraCapture onCapture={handlePhotoCapture} isOpen={isCameraOpen} />
        {photo && (
          <Grid item xs={12} textAlign="center">
            <Box sx={{ marginTop: '2px' }}> {/* Increased marginTop for additional space */}
              <Typography variant="h6" gutterBottom>
                Captured Photo:
              </Typography>
              <img src={photo} alt="Captured" style={{ width: '100%', maxWidth: '300px', borderRadius: '8px' }} />
            </Box>
          </Grid>
        )}
        <Grid item xs={12} textAlign="center">
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleSaveToCSV}
            sx={{ marginTop: '1px', marginBottom: '1px' }} // Add bottom margin for spacing
            disabled={!punchTime}
          >
            Save to CSV
          </Button>
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleLogout}
            sx={{ marginTop: '2px', marginBottom: '2px' }} // Add bottom margin for spacing
          >
            Logout
          </Button>
        </Grid>
      </Grid>
    </Box>
   
  );
};

export default PunchingPage;
