// src/utils/getLoginCredentials.js
export const getLoginCredentials = async () => {
    try {
      const response = await fetch('/logincredentials.txt'); // Fetch from the public folder
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.text();
      // Process the data to create an object of credentials
      return data
        .trim()
        .split('\n')
        .map(line => line.split(',')) // Split by comma to separate user:pass and branch
        .reduce((acc, [userPass, branch]) => {
          const [user, pass] = userPass.split(':'); // Further split user:pass
          acc[user] = { pass, branch }; // Store password and branch
          return acc;
        }, {});
    } catch (error) {
      console.error('Error fetching login credentials:', error);
      return null; // Return null in case of error
    }
  };
  