import React, { useRef, useEffect } from 'react';
import { Box, Button } from '@mui/material'; // Import MUI components

const CameraCapture = ({ onCapture, isOpen }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const initCamera = async () => {
      if (isOpen) {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ video: true });
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
          }
        } catch (error) {
          console.error('Error accessing camera:', error);
        }
      }
    };

    initCamera();

    // Cleanup function
    const currentVideoRef = videoRef.current; // Store current ref value in a variable
    return () => {
      if (currentVideoRef) {
        const stream = currentVideoRef.srcObject;
        if (stream) {
          const tracks = stream.getTracks();
          tracks.forEach(track => track.stop()); // Stop all tracks to release camera
        }
        currentVideoRef.srcObject = null; // Reset the srcObject
      }
    };
  }, [isOpen]); // Depend on isOpen to re-initialize when the camera is opened/closed

  const capturePhoto = () => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    context.drawImage(videoRef.current, 0, 0);
    const imageData = canvas.toDataURL('image/png');
    onCapture(imageData); // Send captured image data back to parent component
  };

  return (
    isOpen && (
      <Box 
        display="flex" 
        flexDirection="column" 
        alignItems="center" 
        justifyContent="center" 
        sx={{ margin: 2 }} // Add margin for spacing
      >
        <video ref={videoRef} autoPlay style={{ width: '100%', maxHeight: '400px', borderRadius: '8px' }} />
        <Button 
          variant="contained" 
          color="success" 
          onClick={capturePhoto} 
          sx={{ marginTop: 2 }} // Add margin for spacing
        >
          Capture
        </Button>
      </Box>
    )
  );
};

export default CameraCapture;
